window.setTimeout(function() {
	"use strict";
	var oceanSpecificIFrame = "#oceanIFrame";
	var iframeSelector = document.querySelector(oceanSpecificIFrame) ? oceanSpecificIFrame : "iframe";
	iFrameResize({
		autoResize: true,
		//log: true,
		inPageLinks: true,
		resizedCallback: function(messageData) {
			//console.log(messageData);
		},
		messageCallback: function(messageData) {
			//console.log(messageData);
			if (messageData.message === "scrollToPageTop") {
				document.querySelector("body").scrollIntoView(true);
			}
			if (messageData.message === "scrollToIFrameTop") {
				document.querySelector("iframe").scrollIntoView(true);
			}
		}
	}, iframeSelector);
}, 0);